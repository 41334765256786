import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { BlogJsonLd } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";

import { CardPost, HeroFilter, Page, Section } from "components";
import { formatBlogPosts, getCategory, getCategoryFilters } from "utils";

// TODO:
// - create GridPosts component

const TemplatePostCategory = ({
  data: { postCategories, posts },
  location,
}) => {
  const category = getCategory(posts);
  const formattedPosts = formatBlogPosts(posts, location.origin);
  const filterButtons = getCategoryFilters(postCategories, "/blog", {
    label: "All Posts",
  });

  const seoOptions = {
    title: `${category} Blog`,
    jsonLd: (
      <BlogJsonLd
        url={location.href}
        headline={`${category} Blog`}
        image={`${location.origin}/logo.png`}
        // images={images}
        posts={formattedPosts}
        // authorName={author}
        // description={description}
      />
    ),
  };

  const headerOptions = {
    buttons: filterButtons,
    heading: `${category} Blog`,
  };

  const sectionOptions = {
    className: "feature",
    containerProps: {
      maxWidth: "lg",
    },
  };

  return (
    <Page seo={seoOptions}>
      <HeroFilter {...headerOptions} />
      <Section {...sectionOptions}>
        <Grid container spacing={2}>
          {posts.edges.map(({ node: { fields, frontmatter, id } }) => (
            <Grid item key={id} xs={12} md={6} lg={12}>
              <CardPost path={fields.slug} {...frontmatter} />
            </Grid>
          ))}
        </Grid>
      </Section>
    </Page>
  );
};

TemplatePostCategory.propTypes = {
  data: PropTypes.shape({
    postCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string,
        })
      ),
    }),
    posts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              category: PropTypes.string,
              date: PropTypes.string,
              description: PropTypes.string,
              image: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                  }),
                }),
              }),
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const postsByCategoryQuery = graphql`
  query PostsByCategory($category: String!) {
    postCategories: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/blog/" }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___category) {
        category: fieldValue
      }
    }
    posts: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/blog/" }
        frontmatter: { category: { eq: $category }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            date(formatString: "MMM. DD, YYYY")
            description
            draft
            image {
              childImageSharp {
                fluid(maxWidth: 720, maxHeight: 720, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
          }
          id
        }
      }
    }
  }
`;

export default TemplatePostCategory;
